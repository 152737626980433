import { M as MyUtil } from "./MyUtil.js";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
import "lodash";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var handleSubmit = ref.handleSubmit;
    return [_c("a-modal", { attrs: { "ok-text": "Create", "title": "Edit Assign Category", "after-close": _vm.afterModalClose, "width": 700 }, on: { "ok": _vm.submit }, scopedSlots: _vm._u([{ key: "footer", fn: function() {
      return [_c("a-button", { on: { "click": _vm.cancel } }, [_vm._v("Cancel")]), _c("a-button", { attrs: { "loading": _vm.isLoading, "type": "primary" }, on: { "click": function($event) {
        return handleSubmit(_vm.submit);
      } } }, [_vm._v("Update")])];
    }, proxy: true }], null, true), model: { value: _vm.visible, callback: function($$v) {
      _vm.visible = $$v;
    }, expression: "visible" } }, [_c("a-row", { attrs: { "gutter": 24 } }, [_c("a-col", { attrs: { "span": 4 } }, [_c("select-input", { key: "countryId", attrs: { "form-item": "", "label": "Country", "placeholder": "Select Country", "data-source": _vm.countries, "source": "countryId", "source-label": "country", "value": _vm.entity.countryId, "disabled": true }, on: { "change": function($event) {
      return _vm.storeValue("countryId", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 6 } }, [_c("select-input", { key: "itemSubClassFlag", attrs: { "form-item": "", "label": "Item/Subclass", "placeholder": "Select Item/Subclass", "data-source": _vm.itemSubclassList, "value": _vm.entity.itemSubClassFlag, "source": "value", "source-label": "name", "default-active-first-option": "" }, on: { "change": function($event) {
      return _vm.storeValue("itemSubClassFlag", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 7 } }, [_c("text-input", { key: "subClass", attrs: { "form-item": "", "value": _vm.entity.subClass, "label": "Subclass", "placeholder": "Type Subclass" }, on: { "change": function($event) {
      return _vm.storeValue("subClass", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 7 } }, [_c("text-input", { key: "itemCode", attrs: { "form-item": "", "value": _vm.entity.itemCode, "label": "Item Code", "placeholder": "Type Item Code" }, on: { "change": function($event) {
      return _vm.storeValue("itemCode", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 6 } }, [_c("select-input", { key: "startDate", attrs: { "form-item": "", "value": _vm.entity.startDate, "label": "Start Date", "source": "intStartDate", "source-label": "finPeriodChar", "reference": "web-analytics.common.ppv-fin-period", "placeholder": "Select Start Date", "default-active-first-option": "" }, on: { "change": _vm.onStartDateChange } })], 1), _c("a-col", { attrs: { "span": 6 } }, [_c("select-input", { key: "endDate", attrs: { "form-item": "", "value": _vm.entity.endDate, "label": "End Date", "source": "intEndDate", "source-label": "finPeriodChar", "reference": "web-analytics.common.ppv-fin-period", "placeholder": "Select End Date", "default-active-first-option": "" }, on: { "change": _vm.onEndDateChange } })], 1), _c("a-col", { attrs: { "span": 6 } }, [_c("select-input", { key: "categoryId", attrs: { "form-item": "", "value": _vm.entity.categoryId, "label": "PPV Category", "source": "categoryId", "source-label": "category", "data-source": _vm.categories, "placeholder": "Select Category", "default-active-first-option": "" }, on: { "change": function($event) {
      return _vm.storeValue("categoryId", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 6 } }, [_c("select-input", { key: "versionId", attrs: { "form-item": "", "value": _vm.entity.versionId, "label": "Version", "source": "versionId", "source-label": "versionName", "data-source": _vm.versions, "placeholder": "Select Version", "default-active-first-option": "" }, on: { "change": function($event) {
      return _vm.storeValue("versionId", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 8 } }, [_c("text-input", { key: "project", attrs: { "form-item": "", "value": _vm.entity.project, "label": "Project", "placeholder": "Type Project" }, on: { "change": function($event) {
      return _vm.storeValue("project", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 8 } }, [_c("select-input", { key: "allocationType", attrs: { "form-item": "", "value": _vm.entity.allocationType, "label": "Allocation Type", "source": "allocationTypeCode", "source-label": "allocationType", "reference": "web-analytics.common.ppv-allocation-type", "placeholder": "Select Allocation Type", "default-active-first-option": "" }, on: { "change": _vm.onAllocationTypeChange } })], 1), _c("a-col", { attrs: { "span": 8 } }, [_c("text-input", { key: "unitRate", attrs: { "form-item": "", "label": "Unit Rate", "placeholder": "Type Unit Rate", "rules": _vm.regex12digits3fracNotRequired, "max-length": 16, "value": _vm.entity.unitRate }, on: { "change": function($event) {
      return _vm.storeValue("unitRate", $event);
    } } })], 1)], 1)], 1)];
  } }]) });
};
var staticRenderFns$1 = [];
var EditAssignCategories_vue_vue_type_style_index_0_lang = /* @__PURE__ */ (() => "")();
const __vue2_script$1 = {
  name: "EditAssignCategories",
  mixins: [MyUtil],
  inject: ["crud"],
  data() {
    return {
      editForm: {},
      itemSubclassList: [
        { value: "S", name: "Subclass" },
        { value: "I", name: "Item" }
      ],
      allQuery: [["All", false]],
      versions: [],
      categories: [],
      visible: false,
      isLoading: false,
      unitRateValue: null,
      dates: [],
      startYear: "",
      endYear: ""
    };
  },
  computed: {
    entity() {
      return this.crud.getEntity();
    }
  },
  created() {
    this.getCountries();
    this.getCategories();
    this.getVersions();
    this.getDates();
    this.storeValues(this.entity);
    this.storeValue("UpdateType", "update");
  },
  mounted() {
    this.visible = true;
  },
  methods: {
    getVersions() {
      this.axios.get(`${this.apiUrl}/web-analytics/common/ppv-version`).then((res) => {
        this.versions = [...res.data];
      });
    },
    getCategories() {
      this.axios.get(`${this.apiUrl}/web-analytics/common/ppv-category`).then((res) => {
        this.categories = [...res.data];
      });
    },
    parseIntStartDate(value) {
      if (!value) {
        return "";
      }
      return this.$moment(`${value.intStartDate}`).format("YYYY/MM/DD");
    },
    parseIntEndDate(value) {
      if (!value) {
        return "";
      }
      return this.$moment(`${value.intEndDate}`).format("YYYY/MM/DD");
    },
    getDates() {
      this.axios.get(`${this.apiUrl}/web-analytics/common/ppv-fin-period`).then((res) => {
        this.dates = [...res.data];
      });
    },
    onStartDateChange(value) {
      if (this.dates) {
        var result = this.dates.find(({ intStartDate }) => intStartDate === value);
        if (result) {
          this.startYear = result.finPeriodChar.slice(0, 4);
        }
        this.storeValue(`startDate`, value);
      }
      return;
    },
    onEndDateChange(value) {
      if (this.dates) {
        var result = this.dates.find(({ intEndDate }) => intEndDate === value);
        if (result) {
          this.endYear = result.finPeriodChar.slice(0, 4);
        }
        this.storeValue(`endDate`, value);
      }
      return;
    },
    onAllocationTypeChange(value) {
      this.storeValue(`allocationType`, value);
      if (value === "A") {
        this.unitRateValue = 0;
        this.storeValue(`unitRate`, this.unitRateValue);
      } else {
        this.unitRateValue = null;
      }
    },
    storeValue(resourceKey, value) {
      this.editForm = {
        ...this.editForm,
        [resourceKey]: value
      };
      this.crud.updateEntity("editForm", {
        resourceKey,
        value
      });
    },
    storeValues(entity) {
      Object.entries(entity).forEach(([resourceKey, value]) => this.storeValue(resourceKey, value));
    },
    cancel() {
      this.visible = false;
    },
    afterModalClose() {
      this.$router.push("/web-analytics/assign-categories");
    },
    async submit() {
      this.isLoading = true;
      if (this.startYear != this.endYear) {
        this.$notification["error"]({
          message: "Start and End Dates must be the same year"
        });
        this.isLoading = false;
        return;
      }
      await this.crud.submitEntity("replace").then(() => {
        this.crud.clearEntity("editForm");
        this.crud.fetchList();
        this.visible = false;
        this.isLoading = false;
      }).catch(() => this.isLoading = false);
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditAssignCategories = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", { attrs: { "api-url": _vm.apiUrl, "name": "web-analytics.common.ppv-sub-class" } }), _c("resource", { attrs: { "api-url": _vm.apiUrl, "name": "web-analytics.common.ppv-category" } }), _c("resource", { attrs: { "api-url": _vm.apiUrl, "name": "web-analytics.common.ppv-allocation-type" } }), _c("resource", { attrs: { "api-url": _vm.apiUrl, "name": "web-analytics.common.ppv-fin-period" } }), _c("resource", { attrs: { "api-url": _vm.apiUrl, "name": "web-analytics.category-assigns", "redirect-route": "/web-analytics/assign-categories" } }, [_c("edit-assign-categories")], 1)], 1);
};
var staticRenderFns = [];
var index_vue_vue_type_style_index_0_lang = /* @__PURE__ */ (() => "")();
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    EditAssignCategories
  },
  data() {
    return {
      apiUrl,
      EditAssignCategories
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
